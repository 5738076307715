<template>
  <div  v-if="isMobile" class="phoneDetailsBox">
    <el-page-header @back="goBack" content="详情页面">
    </el-page-header>
    <el-divider></el-divider>
    <div class="detailsContent">
      <h2 class="detailsContentTitle">{{detail.name}}</h2>
      <div class="timeBox">
        <span>时间：{{detail.createTime|formatTimer()}}</span>
        <span>浏览次数：{{detail.count}}次</span>
      </div>
      <el-divider></el-divider>
      <div v-if="detail.richText " class="detail_html richText ql-editor"  v-html="detail.richText" @click="clickDom($event)">
      </div>
      <div v-else class="no_detail">
        内容补充中...
      </div>
    </div>

  </div>

  <div  v-else class="detailsBox">
    <el-page-header @back="goBack" content="详情页面">
    </el-page-header>
    <el-divider></el-divider>
    <div class="detailsContent">
      <h2 class="title">{{detail.name}}</h2>
      <div class="timeBox">
        <span>时间：{{detail.createTime|formatTimer()}}</span>
        <span>浏览次数：{{detail.count}}次</span>
      </div>
      <el-divider></el-divider>
      <div v-if="detail.richText " class="detail_html richText ql-editor"  v-html="detail.richText" @click="clickDom($event)">
      </div>
      <div v-else class="no_detail">
        内容补充中...
      </div>
    </div>
  </div>
</template>

<script>
import ItemMixins from "../item.mixins";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  mixins: [ItemMixins],
  data() {
    return {
      detail: {},
    }
  },
  watch: {
    // 监听路由变化，路由变化的时候请求页面数据
    '$route': function () {
      this.init()
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async init() {
      let id = this.$route.params.id
      this.detail = await this.getDetailById(id)
      this.detail.richText = this.detail.richText.replace(/\<img src="/gi, '<img src="'+this.constUrl)
      this.$emit('topPic', this.detail.picture)
    },
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
  /*手机端样式*/
  .phoneDetailsBox {
    width: 100% ;
    margin: auto;
    .timeBox {
      width: 100%;
      text-align: center;
      display: block;
      span {
        margin-right: 3%;
        color: #2a2a2a;
      }
    }
    .banner {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: auto;
      img {
        width: 100%;
      }
    }

    .detailsBox {
      width: 100%;
      margin: auto;
      .el-page-header {
        margin: 0vh 1vh;
        font-size: 120%;
        .el-icon-back, .el-page-header__title, .el-page-header__content {
          line-height: 50px;
          -webkit-background-clip: text;
          height: 50px;
        }
      }
    }


    .detailsContent {
      margin: auto;
      height: 100%;
      .detailsContentTitle {
        display: block;
        text-align: center;
        font-size: 7rem;
        font-weight: 600;
        background-image: linear-gradient(to left, #43d5fd, #0385ff);
        line-height: 50px;
        -webkit-background-clip: text;
        color: transparent;
        min-height: 50px;
      }
      img {
        margin: auto;
        width: 50%;
        display: block;
      }
    }

    .detail_html {
      padding: 5vw;
      width: 100%;
      box-sizing: border-box;

      p {
        text-indent: 20px;
        font-size: 1rem;
        line-height: 2vw;

        line-height: 8rem;
      }

      //编写富文本是，图片选择居中，这里写样式实现
      .ql-align-center {
        text-align: center;
      }

      img {
        width: 50%;
      }

    }

    .no_detail {
      text-align: center;
      height: 30vh;
      line-height: 30vh;
      color: #b0afaf
    }
  }
</style>
